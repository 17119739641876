<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    width?: number;
    height?: number | "auto";
    closeable?: boolean;
    show: boolean;
    autoclose?: boolean;
    bgcolor?: string;
  }>(),
  {
    width: 600,
    height: "auto",
    closeable: true,
    show: true,
    autoclose: true,
    bgcolor: "bg-white",
  },
);

const emit = defineEmits(["close"]);

// -----------------------
// reactive properties
// -----------------------
const content = ref(null);

// -----------------------
// computed properties
// -----------------------
const contentStyle = computed(() => {
  let styles = `width: ${props.width}px; max-width: 100%;`;

  if (props.height !== "auto") {
    styles += `;min-height: ${props.height}px`;
  }

  return styles;
});

const contentClass = computed(() => {
  const classes: string[] = [
    "m-3",
    "mx-auto",
    "my-[10px]",
    "content",
    "relative",
    "p-[25px]",
    "max-w-full",
  ];

  classes.push(props.bgcolor);

  return classes.join(" ");
});

// -----------------------
// hooks
// -----------------------
onUpdated(() => {
  // Disable body overflow when modal is visible
  const classList = document.body.classList;

  if (props.show) {
    classList.add("overflow-hidden");

    // Close on escape
    document.body.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Escape") {
          emit("close");
        }
      },
      { once: true },
    );
  } else {
    classList.remove("overflow-hidden");
  }
});

// -----------------------
// helper functions
// -----------------------
const handleClick = (e: PointerEvent) => {
  if (!props.autoclose) return;

  let el: HTMLElement = e.target;

  while (el && el.tagName.toLowerCase() !== "body") {
    if (el === content.value) {
      return;
    }

    el = el.parentNode;
  }

  emit("close");
};
</script>
<template>
  <div
    v-if="show"
    class="z-modal fixed w-full h-full top-0 left-0 backdrop-blur overflow-y-scroll base-modal drop-shadow"
    @click="handleClick"
  >
    <div class="max-w-full">
      <div ref="content" :class="contentClass" :style="contentStyle">
        <button
          v-if="closeable"
          class="w-[12px] h-[12px] absolute top-[12px] right-[12px] flex justify-center items-center"
          @click="$emit('close')"
        >
          <i class="m-icon-cancel ml-[1px] text-xs"></i>
        </button>
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>
